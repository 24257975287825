<template>
<p>
  In this Quickstart, you will learn how to:
</p>
<CCard color="light">
  <CCardBody>
    <ol>
      <li>Sign up for Whoosh</li>
      <li>Set up your development environment to send messages</li>
      <li>Send your first SMS</li>
    </ol>
  </CCardBody>
</CCard>
</template>

<script>
export default {}
</script>