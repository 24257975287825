import { createRouter, createWebHistory } from 'vue-router'

import JavaQuickstartView from '../views/JavaQuickstartView'
import NodejsQuickstartView from '../views/NodejsQuickstartView'
import PythonQuickstartView from '../views/PythonQuickstartView'
import RubyQuickstartView from '../views/RubyQuickstartView'
import JavaDemo from '../views/JavaDemoView'
import NodeDemo from '../views/NodejsDemoView'
import PythonDemo from '../views/PythonDemoView'
import RubyDemo from '../views/RubyDemoView'
import CurlDemo from '../views/CurlDemoView'
import SendMessageView from '../views/SendMessageView'
import MonitorMessageStatusView from '../views/MonitorMessageStatusView'

const routes = [
  {
    path: '/send_message',
    name: 'SendMessage',
    component: SendMessageView
  },
  {
    path: '/monitor_message_status',
    name: 'MonitorMessageStatus',
    component: MonitorMessageStatusView
  },
  {
    path: '/node',
    name: 'NodeQuickstart',
    component: NodejsQuickstartView
  },
  {
    path: '/java',
    name: 'JavaQuickstart',
    component: JavaQuickstartView
  },
  {
    path: '/python',
    name: 'PythonQuickstart',
    component: PythonQuickstartView
  },
  {
    path: '/ruby',
    name: 'RubyQuickstart',
    component: RubyQuickstartView
  },
  {
    path: '/node_demo',
    name: 'NodeDemo',
    component: NodeDemo
  },
  {
    path: '/java_demo',
    name: 'JavaDemo',
    component: JavaDemo
  },
  {
    path: '/python_demo',
    name: 'PythonDemo',
    component: PythonDemo
  },
  {
    path: '/ruby_demo',
    name: 'RubyDemo',
    component: RubyDemo
  },
  {
    path: '/curl_demo',
    name: 'CurlDemo',
    component: CurlDemo
  },
  {
    path: '/',
    name: 'SendMessage',
    component: SendMessageView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
