<template>
<DocPage v-if="isNavKeysSet" :codeBlocksUsed="[]">
  <template #mainContent>
    <h1>{{ title }}</h1>
    <hr />
    <div class="w-100">
      <video muted controls class="w-100">
        <source :src="demoVideo" type="video/mp4">
      </video>
    </div>
  </template>
</DocPage>
</template>

<script>
import DocPage from '@/components/DocPage.vue'

export default {
  props: {
    navKeys: {
      type: Array,
      required: true
    },
    demoVideo: {
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },
  computed: {
    isNavKeysSet () {
      return this.$store.state.navKeys
    }
  },
  mounted () {
    this.$store.commit('setNavKeys', this.navKeys)
  },
  components: { DocPage }
}
</script>
