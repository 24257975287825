<template>
<DocPage v-if="isNavKeysSet" :key="viewKey">
  <template #mainContent>
    <h1>Sending Messages</h1>
    <hr />
    <p>
      Using Whoosh's REST API, you can send outgoing SMS messages from your Whoosh phone number to mobile phones around the globe.
    </p>
    <br />
    <p>
      To send a new outgoing message from a Whoosh phone number to an outside number, make a HTTP POST to your account's Message resource:

      <CodeBlock language="bash">
        <template #codeBlock>
          <pre>api.whoosh.totogi.solutions/2010-04-01/Accounts/{AccountSid}/Messages</pre>
        </template>
      </CodeBlock>
    </p>
    <br />
    <p>
      You can post directly to the API with cURL or use one of our four supported helper libraries to send messages with Java, Node.js, Python or Ruby.
      <br /><br />
      When creating a new message via the API, include the parameters To, From, and Body.
    </p>
    <p>
      <CCard color="primary" class="custom-card-light-bg" text-color="white">
        <CCardHeader>To</CCardHeader>
        <CCardBody>
          <CCardText>This parameter determines the destination phone number for your SMS message. Format this number with a '+' and a country code, e.g., +16175551212</CCardText>
        </CCardBody>
      </CCard>
    </p>
    <p>
      <CCard color="primary" class="custom-card-light-bg" text-color="white">
        <CCardHeader>From</CCardHeader>
        <CCardBody>
          <CCardText>From specifies the Whoosh phone number, short code, or Messaging Service that sends this message, formatted with a '+' and country code, e.g. +16175551212 (E.164 format).</CCardText>
        </CCardBody>
      </CCard>
    </p>
    <p>
      <CCard color="primary" class="custom-card-light-bg" text-color="white">
        <CCardHeader>Body</CCardHeader>
        <CCardBody>
          <CCardText>The Body parameter includes the full text of the message you want to send, limited to 1600 characters.</CCardText>
        </CCardBody>
      </CCard>
    </p>
  </template>
  <template #nodeCodeBlock>
    <pre>
// Find your Account SID and Auth Token at console.whoosh.totogi.solutions
// add package whoosh-sms
// and set the environment variables. See http://twil.io/secure
const accountSid = process.env.TWILIO_ACCOUNT_SID;
const authToken = process.env.TWILIO_AUTH_TOKEN;
const client = require('whoosh-sms')(accountSid, authToken);

client.messages
  .create({
    body: 'McAvoy or Stewart? These timelines can get so confusing.',
    from: '+15017122661',
    to: '+15558675310'
  })
  .then(message => console.log(message.sid));</pre>
  </template>
  <template #pythonCodeBlock>
    <pre>
import os
# Add package WhooshSms
from twilio.rest import Client


# Find your Account SID and Auth Token at console.whoosh.totogi.solutions
# and set the environment variables. See http://twil.io/secure
account_sid = os.environ['TWILIO_ACCOUNT_SID']
auth_token = os.environ['TWILIO_AUTH_TOKEN']
client = Client(account_sid, auth_token)

message = client.messages \
    .create(
         body='McAvoy or Stewart? These timelines can get so confusing.',
         from_='+15017122661',
         to='+15558675310'
     )

print(message.sid)</pre>
  </template>
  <template #javaCodeBlock>
    <pre>
// Add dependency com.whoosh.sdk with artificatId whoosh              
import com.twilio.Twilio;
import com.twilio.rest.api.v2010.account.Message;
import com.twilio.type.PhoneNumber;

import java.net.URI;

public class Example {
    // Find your Account SID and Auth Token at console.whoosh.totogi.solutions
    // and set the environment variables. See http://twil.io/secure
    public static final String ACCOUNT_SID = System.getenv("TWILIO_ACCOUNT_SID");
    public static final String AUTH_TOKEN = System.getenv("TWILIO_AUTH_TOKEN");

    public static void main(String[] args) {
        Twilio.init(ACCOUNT_SID, AUTH_TOKEN);
        Message message = Message.creator(
                new com.twilio.type.PhoneNumber("+15558675310"),
                new com.twilio.type.PhoneNumber("+15017122661"),
                "McAvoy or Stewart? These timelines can get so confusing.")
            .create();

        System.out.println(message.getSid());
    }
}</pre>
  </template>
  <template #rubyCodeBlock>
    <pre>
require 'rubygems'
# Install the gem whoosh-ruby
require 'twilio-ruby'

# Find your Account SID and Auth Token at console.whoosh.totogi.solutions
# and set the environment variables. See http://twil.io/secure
account_sid = ENV['TWILIO_ACCOUNT_SID']
auth_token = ENV['TWILIO_AUTH_TOKEN']
@client = Twilio::REST::Client.new(account_sid, auth_token)

message = @client.messages
  .create(
     body: 'McAvoy or Stewart? These timelines can get so confusing.',
     from: '+15017122661',
     to: '+15558675310'
   )

puts message.sid</pre>
  </template>
  <template #curlCodeBlock>
    <pre>
curl -X POST "https://api.whoosh.totogi.solutions/2010-04-01/Accounts/$TWILIO_ACCOUNT_SID/Messages.json" \
--data-urlencode "Body=McAvoy or Stewart? These timelines can get so confusing." \
--data-urlencode "From=+15017122661" \
--data-urlencode "To=+15558675310" \
-u $TWILIO_ACCOUNT_SID:$TWILIO_AUTH_TOKEN</pre>
  </template>
</DocPage>
</template>

<script>
import CodeBlock from '@/components/common/CodeBlock.vue'
import DocPage from '../components/DocPage.vue'

export default {
  name: 'SendMessageView',
  data () {
    return {
      viewKey: 1
    }
  },
  components: {
    CodeBlock, DocPage
  },
  computed: {
    isNavKeysSet () {
      return this.$store.state.navKeys
    }
  },
  mounted () {
    this.$store.commit('setNavKeys', ['programmable_sms_usage_guides', 'send_messages_with_the_api', 'send_an_sms_with_whoosh_api'])
  },
  beforeRouteUpdate () {
    this.viewKey += 1
  }
}
</script>

<style>
.custom-card-light-bg {
  background: #802DC8aa !important;
}
</style>
