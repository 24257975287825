<template>
<CSidebar visible class="sidebar-height sidebar-width sidebar-custom-background sidebar-custom-color border-right">
  <CSidebarNav>
    <li class="nav-title sidebar-title border-bottom border-top mt-0">Send SMS with Whoosh Programmable Messaging</li>
    <div style="overflow: scroll !important;" class="pb-4">
      <DocPageSidebarNavElement v-for="navElement in navStructure" :key="navElement.title" :navElement="navElement" />
    </div>
  </CSidebarNav>
</CSidebar>
</template>

<script>
import { navStructure } from '../utils/sidebarNavStructure'
import DocPageSidebarNavElement from './DocPageSidebarNavElement.vue';

export default {
  data () {
    return {
      navStructure
    }
  },
  components: { DocPageSidebarNavElement }
}
</script>

<style>
.sidebar-custom-background {
  background: #001d3d;
}
.sidebar-custom-color {
  color: #979aaa !important;
}
.sidebar-height {
  min-height: calc(100vh - 48px);
  max-height: calc(100vh - 48px);
}
.sidebar-width {
  min-width: 300px;
  max-width: 300px;
}
.sidebar-title {
  text-transform: none !important;
  font-size: 120% !important;
}
</style>