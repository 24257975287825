<template>
<h1>Sign up for Whoosh</h1>
<CCard>
  <CCardBody>
    If you already have a Whoosh account, you're all set here! Feel free to jump to the next step.
  </CCardBody>
</CCard>
<br />
<p>
  You can sign up for a free Whoosh account <a target="_blank" href="https://console.whoosh.totogi.solutions">here</a>.
</p>
</template>

<script>
export default {}
</script>