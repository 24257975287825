import { createStore } from 'vuex'

export default createStore({
  state () {
    return {
      navKeys: null
    }
  },
  getters: {},
  mutations: {
    setNavKeys (state, navKeys) {
      state.navKeys = navKeys
    }
  },
  actions: {}
})