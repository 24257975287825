<template>
<DemoVideo
  title="Node.js SDK Demo"
  :demoVideo="videoFile"
  :navKeys="['demos', 'node_demo']"
  :key="viewKey"
/>
</template>

<script>
import whoosh_node from '../assets/whoosh_node.mp4'

import DemoVideo from '@/components/DemoVideo.vue'

export default {
  data () {
    return {
      videoFile: whoosh_node,
      viewKey: 1
    }
  },
  components: { DemoVideo },
  beforeRouteUpdate () {
    this.viewKey += 1
  }
}
</script>