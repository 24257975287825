<template>
<DocPage v-if="isNavKeysSet" :codeBlocksUsed="['java']" :key="viewKey">
  <template #mainContent>
    <h1>Programmable Messaging Quickstart for Java</h1>
    <hr />
    <QuickstartSection />
    <hr />
    <SignUpSection />
    <hr />
    <h1>Download the standalone Whoosh Java Helper Library</h1>
    <p>Got Java all set up and ready to go? Fantastic!</p>
    <p>
      Next, download the standalone <a target="_blank" href="https://github.com/totogi/whoosh-java-releases">Whoosh Java Helper Library</a>. With that library, you'll have Java classes that help you call out to Whoosh API's using Java, along with all the other dependencies you'll need to get going. We'll be using this "fat jar" file with all the dependencies in this SMS Quickstart. When you download the fat jar file, download the one with a name similar to <strong>whoosh-0.1.x-jar-with-dependencies.jar</strong>.
    </p>
    <hr />
    <h1>Send an outbound SMS message with Java</h1>
    <p>Now that we have the JDK set up and the Whoosh Java Helper Library downloaded, we can send an outbound text message with a single API request. Create and open a new file called <mark>Example.java</mark> and type or paste in this code sample. Move the Whoosh Java Helper Library to be in the same directory as <mark>Example.java</mark>.</p>
    <p>
      You'll need to edit this file a little more before your message will send.
    </p>
    <h2  class="pt-3 pb-2">Replace the placeholder credential values</h2>
    <p>
      Swap the placeholder values for <mark>accountSid</mark> and <mark>authToken</mark> with your personal Whoosh credentials. Go to https://console.whoosh.totogi.solutions and log in. On this page, you'll find your unique Account SID and Auth Token, which you'll need any time you send messages through the Whoosh client
    </p>
    <p>
      Open <mark>Example.java</mark> and replace the values for <mark>accountSid</mark> and <mark>authToken</mark> with your unique values.
    </p>
    <CCard>
      <CCardBody>
        Please note: it's okay to hardcode your credentials when getting started, but you should use environment variables to keep them secret before deploying to production. 
      </CCardBody>
    </CCard>
    <h2  class="pt-3 pb-2">Replace the "to" phone number</h2>
    <p>
      Replace the <mark>to</mark> phone number with your mobile phone number. This can be any phone number that can receive text messages, but it's a good idea to test with your own phone so you can see the magic happen! As above, you should use E.164 formatting for this value.
    </p>
    <p>
      <mark>[+][country code][phone number including area code]</mark>
    </p>
    <p>
      Save your changes and compile this Java class from your terminal, replacing 0.1.0 with the relevant version number:
    </p>
    <CodeBlock language="bash">
      <template #codeBlock>
        <pre>javac -cp whoosh-0.1.0-jar-with-dependencies.jar Example.java</pre>
      </template>
    </CodeBlock>
    <p>We need to include that Whoosh jar with the dependencies to compile our class from the command line. If you are using an Integrated Development Environment (IDE) such as IntelliJ IDEA, Netbeans or Eclipse, you can simply add that Whoosh jar to your classpath or your project libraries like any other dependency. You can also use a build tool like Maven or Gradle to build and run your Java application - just specify Whoosh's helper library as a dependency.</p>
    <p>Once you have the Java class built, you will need to run it - if you are running it from the command line on macOS or Linux, the command will look like this:</p>
    <CodeBlock language="bash">
      <template #codeBlock>
        <pre>java -cp .:whoosh-0.1.0-jar-with-dependencies.jar Example.java</pre>
      </template>
    </CodeBlock>
    <p>On Windows, the equivalent command looks like this:</p>
    <CodeBlock language="bash">
      <template #codeBlock>
        <pre>java -cp ".;whoosh-0.1.0-jar-with-dependencies.jar" Example.java</pre>
      </template>
    </CodeBlock>
    <p>The difference is that on Windows, the Java classpath separator on the command line is a semicolon, and on macOS or Linux, it is a colon.</p>
    <p>That's it! In a few moments, you should receive an SMS from your Whoosh number on your phone.</p>
  </template>
  <template #javaCodeBlock>
    <pre>
// Install the Java helper library from https://github.com/totogi/whoosh-java-releases

import com.twilio.Twilio;
import com.twilio.rest.api.v2010.account.Message;
import com.twilio.type.PhoneNumber;

public class Example {
    // Find your Account SID and Auth Token at twilio.com/console
    // and set the environment variables. See http://twil.io/secure
    public static final String ACCOUNT_SID = System.getenv("TWILIO_ACCOUNT_SID");
    public static final String AUTH_TOKEN = System.getenv("TWILIO_AUTH_TOKEN");

    public static void main(String[] args) {
        Twilio.init(ACCOUNT_SID, AUTH_TOKEN);
        Message message = Message.creator(
                new com.twilio.type.PhoneNumber("+14159352345"),
                new com.twilio.type.PhoneNumber("+14158141829"),
                "Where's Wallace?")
            .create();

        System.out.println(message.getSid());
    }
}</pre>
  </template>
</DocPage>
</template>

<script>
import CodeBlock from '@/components/common/CodeBlock.vue'
import DocPage from '../components/DocPage.vue'
import SignUpSection from '@/components/SignUpSection.vue'
import QuickstartSection from '@/components/QuickstartSection.vue'

export default {
  name: 'SendMessageView',
  data () {
    return {
      viewKey: 1
    }
  },
  components: {
    CodeBlock, DocPage, SignUpSection, QuickstartSection
  },
  computed: {
    isNavKeysSet () {
      return this.$store.state.navKeys
    }
  },
  mounted () {
    this.$store.commit('setNavKeys', ['get_started', 'java_quickstart'])
  },
  beforeRouteUpdate () {
    this.viewKey += 1
  }
}
</script>
