<template>
<span :class="topElementClasses">
  <span v-if="collapsedIcon">
    <font-awesome-icon :icon="iconAttr"></font-awesome-icon>&nbsp;
  </span>
  <span v-else>
    <span class="invisible">
      <font-awesome-icon icon="fa-solid fa-file-lines"></font-awesome-icon>
    </span>
    &nbsp;
  </span>
  <span class="flex-grow-1">
    {{ title }}
  </span>
</span>
</template>

<script>
export default {
  props: {
    collapsedIcon: {
      type: String,
      required: false
    },
    expandedIcon: {
      type: String,
      required: false
    },
    title: {
      type: String,
      required: true
    },
    expanded: {
      type: Boolean,
      default: false,
      required: false
    },
    activeNavItem: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  computed: {
    iconAttr () {
      return (this.expanded ? (this.expandedIcon || this.collapsedIcon) : this.collapsedIcon)
    },
    topElementClasses () {
      return (this.activeNavItem ? 'd-flex active-color' : 'd-flex')
    }
  }
}
</script>

<style>
.active-color {
  color: #ff4f58 !important;
}
</style>
