<template>
<CContainer fluid class="px-0 text-start" style="min-height: calc(100vh - 48px); max-height: calc(100vh - 48px);overflow-y: scroll; max-width: 100% !important;">
  <div class="d-flex flex-row">
    <div style="min-height: calc(100vh - 48px); max-height: calc(100vh - 48px);min-width: 300px; max-width: 300px;">
      <DocPageSidebar :navKeys="navKeys" />
    </div>
    <div class="flex-grow-1">
      <CRow class="mx-0">
        <CCol :xs="codeBlocksUsed.length ? 6 : 12" class="px-4 py-4" style="min-height: calc(100vh - 48px); max-height: calc(100vh - 48px);overflow: scroll;">
          <RateThisPage />
          <slot name="mainContent"></slot>
        </CCol>
        <CCol xs="6" v-if="codeBlocksUsed.length" class="code-section-bg pt-3 px-0" style="min-height: calc(100vh - 48px); max-height: calc(100vh - 48px);overflow: scroll;">
          <CNav variant="pills" class="custom-pills-color">
            <CNavItem>
              <CNavLink v-if="codeBlocksUsed.indexOf('node') > -1" @click="setActiveCodeTab('node')" class="text-white" :active="activeCodeTab === 'node'">
                NODE.JS
              </CNavLink>
            </CNavItem>
            <CNavItem>
              <CNavLink v-if="codeBlocksUsed.indexOf('python') > -1" @click="setActiveCodeTab('python')" class="text-white" :active="activeCodeTab === 'python'">
                PYTHON
              </CNavLink>
            </CNavItem>
            <CNavItem>
              <CNavLink v-if="codeBlocksUsed.indexOf('java') > -1" @click="setActiveCodeTab('java')" class="text-white" :active="activeCodeTab === 'java'">
                JAVA
              </CNavLink>
            </CNavItem>
            <CNavItem>
              <CNavLink v-if="codeBlocksUsed.indexOf('ruby') > -1" @click="setActiveCodeTab('ruby')" class="text-white" :active="activeCodeTab === 'ruby'">
                RUBY
              </CNavLink>
            </CNavItem>
            <CNavItem>
              <CNavLink v-if="codeBlocksUsed.indexOf('curl') > -1" @click="setActiveCodeTab('curl')" class="text-white" :active="activeCodeTab === 'curl'">
                CURL
              </CNavLink>
            </CNavItem>
          </CNav>
          <div v-if="codeBlocksUsed.indexOf('node') > -1 && activeCodeTab === 'node'">
            <CodeBlock language="javascript">
              <template #codeBlock>
                <slot name="nodeCodeBlock"></slot>
              </template>
            </CodeBlock>
          </div>
          <div v-if="codeBlocksUsed.indexOf('python') > -1 && activeCodeTab === 'python'">
            <CodeBlock language="python">
              <template #codeBlock>
                <slot name="pythonCodeBlock"></slot>
              </template>
            </CodeBlock>
          </div>
          <div v-if="codeBlocksUsed.indexOf('java') > -1 && activeCodeTab === 'java'">
            <CodeBlock language="java">
              <template #codeBlock>
                <slot name="javaCodeBlock"></slot>
              </template>
            </CodeBlock>
          </div>
          <div v-if="codeBlocksUsed.indexOf('ruby') > -1 && activeCodeTab === 'ruby'">
            <CodeBlock language="ruby">
              <template #codeBlock>
                <slot name="rubyCodeBlock"></slot>
              </template>
            </CodeBlock>
          </div>
          <div v-if="codeBlocksUsed.indexOf('curl') > -1 && activeCodeTab === 'curl'">
            <CodeBlock language="bash">
              <template #codeBlock>
                <slot name="curlCodeBlock"></slot>
              </template>
            </CodeBlock>
          </div>
        </CCol>
      </CRow>
    </div>
  </div>
</CContainer>
</template>

<script>
import CodeBlock from './common/CodeBlock.vue'
import DocPageSidebar from './DocPageSidebar.vue'
import RateThisPage from './common/RateThisPage.vue'

export default {
  data () {
    return {
      activeCodeTabValue: 'node'
    }
  },
  computed: {
    activeCodeTab () {
      return this.activeCodeTabValue
    }
  },
  methods: {
    setActiveCodeTab (codeTabValue) {
      this.activeCodeTabValue = codeTabValue
      return false
    }
  },
  mounted () {
    if (this.codeBlocksUsed.indexOf('node') < 0 && this.codeBlocksUsed.length) {
      this.activeCodeTabValue = this.codeBlocksUsed[0]
    }
    setTimeout(() => {
      document.getElementsByClassName('custom-active-sidebar-element')[0].scrollIntoView()
    }, 390)
    setTimeout(() => {
      document.getElementsByClassName('custom-active-sidebar-element')[0].scrollIntoView()
    }, 500)
    setTimeout(() => {
      document.getElementsByClassName('custom-active-sidebar-element')[0].scrollIntoView()
    }, 1000)
  },
  props: {
    navKeys: {
      type: Array,
      required: true
    },
    codeBlocksUsed: {
      type: Array,
      required: false,
      default: () => { return ['node', 'java', 'python', 'ruby', 'curl'] }
    }
  },
  components: { CodeBlock, DocPageSidebar, RateThisPage }
}
</script>

<style>
.code-section-bg {
  background: #2E3134 !important;
}
.nav-link {
  cursor: pointer;
}
.custom-pills-color {
  color: #979aaa !important;
}
</style>