<template>
<DocPage v-if="isNavKeysSet" :codeBlocksUsed="['node']" :key="viewKey">
  <template #mainContent>
    <h1>Programmable SMS Quickstart for Node.js</h1>
    <hr />
    <p>
      With just a few lines of code, your Node.js application can send text messages with Whoosh Programmable SMS.
    </p>
    <p>
      This Node.js SMS Quickstart will teach you how to do this using our Communications REST API and the Whoosh Node.js helper library.
    </p>
    <QuickstartSection />
    <hr />
    <SignUpSection />
    <hr />
    <h1>Install Node.js and the Whoosh Module</h1>
    <p>
      To send your first SMS, you'll need to have Node.js and the <a target="_blank" href="https://www.npmjs.com/package/whoosh-sms">Whoosh Node Helper Library</a> installed.
    </p>
    <h2  class="pt-3 pb-2">Install Node.js</h2>
    <p>You can check if you already have Node.js version 14 or later installed on your machine by opening up a terminal and running the following command:</p>
    <CodeBlock language="bash">
      <template #codeBlock>
        <pre>node --version</pre>
      </template>
    </CodeBlock>
    <p>You should see something like:</p>
    <CodeBlock language="bash">
      <template #codeBlock>
        <pre>$ node --version
v18.1.2</pre>
      </template>
    </CodeBlock>
    <p>
      If you don't have Node.js installed, <a href="https://nodejs.org/" target="_blank">head over to nodejs.org and download the appropriate installer for your system</a>. Once you've installed Node, return to your terminal and run the command above once again. If you don't see the installed node version, you may need to relaunch your terminal.
    </p>
    <h2  class="pt-3 pb-2">Install the Whoosh Node.js Module</h2>
    <p>Install the Whoosh Node helper library using <a href="https://www.npmjs.com/" target="_blank">npm</a>:</p>
    <CodeBlock language="bash">
      <template #codeBlock>
        <pre>npm install whoosh-sms</pre>
      </template>
    </CodeBlock>
    <p>This will install the Whoosh module so that Node.js scripts in the current directory can use it.</p>
    <hr />
    <h1>Send an Outbound SMS Message with Node.js</h1>
    <p>
      Now that we have Node.js and the Whoosh Node.js library installed, we can send an outbound text message with a single API request. Create and open a new file called <mark>send_sms.js</mark> and type or paste in the code sample.
    </p>
    <p>
      You'll need to edit this file a little more before your message will send.
    </p>
    <h2  class="pt-3 pb-2">Replace the placeholder credential values</h2>
    <p>
      Swap the placeholder values for <mark>accountSid</mark> and <mark>authToken</mark> with your personal Whoosh credentials. Go to https://console.whoosh.totogi.solutions and log in. On this page, you'll find your unique Account SID and Auth Token, which you'll need any time you send messages through the Whoosh client
    </p>
    <p>
      Open <mark>send_sms.js</mark> and replace the values for <mark>accountSid</mark> and <mark>authToken</mark> with your unique values.
    </p>
    <CCard>
      <CCardBody>
        Please note: it's okay to hardcode your credentials when getting started, but you should use environment variables to keep them secret before deploying to production. 
      </CCardBody>
    </CCard>
    <h2  class="pt-3 pb-2">Replace the "to" phone number</h2>
    <p>
      Replace the <mark>to</mark> phone number with your mobile phone number. This can be any phone number that can receive text messages, but it's a good idea to test with your own phone so you can see the magic happen! As above, you should use E.164 formatting for this value.
    </p>
    <p>
      Save your changes and run this script from your terminal:
    </p>
    <CodeBlock language="bash">
      <template #codeBlock>
        <pre>node send_sms.js</pre>
      </template>
    </CodeBlock>
    <p>That's it! In a few moments, you should receive an SMS from your Whoosh number on your phone.</p>
  </template>
  <template #nodeCodeBlock>
    <pre>
// Download the helper library from https://www.npmjs.com/package/whoosh-sms
// Find your Account SID and Auth Token at console.whoosh.totogi.solutions
// and set the environment variables. See http://twil.io/secure
const accountSid = process.env.TWILIO_ACCOUNT_SID;
const authToken = process.env.TWILIO_AUTH_TOKEN;
const client = require('whoosh-sms')(accountSid, authToken);

client.messages
  .create({
     body: 'This is the ship that made the Kessel Run in fourteen parsecs?',
     from: '+15017122661',
     to: '+15558675310'
   })
  .then(message => console.log(message.sid));</pre>
  </template>
</DocPage>
</template>

<script>
import CodeBlock from '@/components/common/CodeBlock.vue'
import DocPage from '../components/DocPage.vue'
import SignUpSection from '@/components/SignUpSection.vue'
import QuickstartSection from '@/components/QuickstartSection.vue'

export default {
  name: 'SendMessageView',
  data () {
    return {
      viewKey: 1
    }
  },
  components: {
    CodeBlock, DocPage, SignUpSection, QuickstartSection
  },
  computed: {
    isNavKeysSet () {
      return this.$store.state.navKeys
    }
  },
  mounted () {
    this.$store.commit('setNavKeys', ['get_started', 'nodejs_quickstart'])
  },
  beforeRouteUpdate () {
    this.viewKey += 1
  }
}
</script>
