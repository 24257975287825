import { createApp } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import './main.scss'
import store from './store'
import router from './router'
import App from './App.vue'

import { faFolderOpen, faFolder, faFileLines, faStar, faVideo } from '@fortawesome/free-solid-svg-icons'
import { faStar as farStar } from '@fortawesome/free-regular-svg-icons'

import coreui from '@coreui/vue'

library.add(faFolder)
library.add(faFolderOpen)
library.add(faFileLines)
library.add(faStar)
library.add(farStar)
library.add(faVideo)

createApp(App).use(store).use(router).use(coreui).component('font-awesome-icon', FontAwesomeIcon).mount('#app')
