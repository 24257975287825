<template>
<DocPage v-if="isNavKeysSet" :codeBlocksUsed="['ruby']" :key="viewKey">
  <template #mainContent>
    <h1>Programmable Messaging Ruby Quickstart</h1>
    <hr />
    <p>With just a few lines of code, your Ruby application can send and receive text messages with Whoosh Programmable SMS.</p>
    <p>
      This Programmable Messaging Quickstart will walk you through the entire process step-by-step, starting with setting up your Whoosh account all the way through sending an SMS using a Messaging Service.
    </p>
    <QuickstartSection />
    <hr />
    <SignUpSection />
    <hr />
    <h1>Install ruby and the Whoosh Helper Library</h1>
    <p>To send your first SMS, you’ll need to have Ruby and the Whoosh Ruby helper library installed.</p>
    <h2 class="pt-3 pb-2">Install Ruby</h2>
    <p>If you’re using a Mac or Linux machine, you probably already have Ruby installed. You can check this by opening up a terminal and running the following command:</p>
    <CodeBlock language="bash">
      <template #codeBlock>
        <pre>ruby --version</pre>
      </template>
    </CodeBlock>
    <p>You should see something like:</p>
    <CodeBlock language="bash">
      <template #codeBlock>
        <pre>ruby 2.7.2</pre>
      </template>
    </CodeBlock>
    <p>Windows users can use <a target="_blank" href="https://rubyinstaller.org/">RubyInstaller</a> to install Ruby.</p>
    <h2 class="pt-3 pb-2">Install the Whoosh Ruby Helper Library</h2>
    <p>The easiest way to install whoosh-ruby is from RubyGems.</p>
    <CodeBlock language="bash">
      <template #codeBlock>
        <pre>gem install whoosh-ruby</pre>
      </template>
    </CodeBlock>
    <p><strong>"Permission Denied"</strong></p>
    <p>If the command line gives you a long error message that says Permission Denied in the middle of it, try running the above commands with sudo:</p>
    <CodeBlock language="bash">
      <template #codeBlock>
        <pre>sudo gem install whoosh-ruby</pre>
      </template>
    </CodeBlock>
    <hr />
    <h1>Send an outbound SMS with Ruby</h1>
    <p>Now that we have Ruby and <mark>whoosh-ruby</mark> installed, we can send an outbound text message with a single API request. Create and open a new file called <mark>send_sms.rb</mark> and type or paste in this code sample.</p>
    <p>
      You'll need to edit this file a little more before your message will send.
    </p>
    <h2  class="pt-3 pb-2">Replace the placeholder credential values</h2>
    <p>
      Swap the placeholder values for <mark>accountSid</mark> and <mark>authToken</mark> with your personal Whoosh credentials. Go to https://console.whoosh.totogi.solutions and log in. On this page, you'll find your unique Account SID and Auth Token, which you'll need any time you send messages through the Whoosh client
    </p>
    <p>
      Open <mark>send_sms.rb</mark> and replace the values for <mark>accountSid</mark> and <mark>authToken</mark> with your unique values.
    </p>
    <CCard>
      <CCardBody>
        Please note: it's okay to hardcode your credentials when getting started, but you should use environment variables to keep them secret before deploying to production. 
      </CCardBody>
    </CCard>
    <h2  class="pt-3 pb-2">Replace the "to" phone number</h2>
    <p>
      Replace the <mark>to</mark> phone number with your mobile phone number. This can be any phone number that can receive text messages, but it's a good idea to test with your own phone so you can see the magic happen! As above, you should use E.164 formatting for this value.
    </p>
    <p>
      <mark>[+][country code][phone number including area code]</mark>
    </p>
    <p>
      Save your changes and run this script from your terminal:
    </p>
    <CodeBlock language="bash">
      <template #codeBlock>
        <pre>ruby send_sms.rb</pre>
      </template>
    </CodeBlock>
    <p>That's it! In a few moments, you should receive an SMS from your Whoosh number on your phone.</p>
  </template>
  <template #rubyCodeBlock>
    <pre>
# Download the twilio-ruby library from https://rubygems.org/gems/whoosh-ruby
require 'twilio-ruby'

# To set up environmental variables, see http://twil.io/secure
account_sid = ENV['TWILIO_ACCOUNT_SID']
auth_token = 'yyyyyyyyyyyyyyyyyyyyyyyyy'
client = Twilio::REST::Client.new(account_sid, auth_token)

from = '+15551234567' # Your Twilio number
to = '+15555555555' # Your mobile phone number

client.messages.create(
from: from,
to: to,
body: "Hey friend!"
)
</pre>
  </template>
</DocPage>
</template>

<script>
import CodeBlock from '@/components/common/CodeBlock.vue'
import DocPage from '../components/DocPage.vue'
import SignUpSection from '@/components/SignUpSection.vue'
import QuickstartSection from '@/components/QuickstartSection.vue'

export default {
  name: 'SendMessageView',
  data () {
    return {
      viewKey: 1
    }
  },
  components: {
    CodeBlock, DocPage, SignUpSection, QuickstartSection
  },
  computed: {
    isNavKeysSet () {
      return this.$store.state.navKeys
    }
  },
  mounted () {
    this.$store.commit('setNavKeys', ['get_started', 'ruby_quickstart'])
  },
  beforeRouteUpdate () {
    this.viewKey += 1
  }
}
</script>
