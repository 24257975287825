<template>
<DocPage v-if="isNavKeysSet" :key="viewKey">
  <template #mainContent>
    <h1>Monitor the status of your message</h1>
    <hr />
    <p>
      By including a StatusCallback URL in your API call for sending a message, you can tell Whoosh where to POST information about your message's status.
    </p>
    <p>
      Please note that Whoosh will make a POST request to your StatusCallback URL.
    </p>
    <p>
      Whoosh will POST to this URL each time the status of your message changes to one of the following:
    </p>
    <p>
      <CCard color="light">
        <CCardBody>
          <ul>
            <li>queued</li>
            <li>failed</li>
            <li>sent</li>
            <li>delivered</li>
            <li>undelivered</li>
          </ul>
        </CCardBody>
      </CCard>
    </p>
    <p>
      Below is an example of the request parameters sent to the StatusCallback URL for a delivered message:
    </p>
    <p>
      <CodeBlock language="json">
        <template #codeBlock>
          <pre>{
  "SmsSid": "SMXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",
  "SmsStatus": "delivered",
  "MessageStatus": "delivered",
  "To": "+15558675310",
  "MessageSid": "SMXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",
  "AccountSid": "ACXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",
  "From": "+15017122661",
  "ApiVersion": "2010-04-01"
}</pre>
        </template>
      </CodeBlock>
    </p>
    <br />
  </template>
  <template #nodeCodeBlock>
    <pre>
// Find your Account SID and Auth Token at console.whoosh.totogi.solutions
// add package whoosh-sms
// and set the environment variables. See http://twil.io/secure
const accountSid = process.env.TWILIO_ACCOUNT_SID;
const authToken = process.env.TWILIO_AUTH_TOKEN;
const client = require('whoosh-sms')(accountSid, authToken);

client.messages
  .create({
    body: 'McAvoy or Stewart? These timelines can get so confusing.',
    from: '+15017122661',
    statusCallback: 'http://postb.in/1234abcd',
    to: '+15558675310'
  })
  .then(message => console.log(message.sid));</pre>
  </template>
  <template #pythonCodeBlock>
    <pre>
import os
# Add package WhooshSms
from twilio.rest import Client


# Find your Account SID and Auth Token at console.whoosh.totogi.solutions
# and set the environment variables. See http://twil.io/secure
account_sid = os.environ['TWILIO_ACCOUNT_SID']
auth_token = os.environ['TWILIO_AUTH_TOKEN']
client = Client(account_sid, auth_token)

message = client.messages \
    .create(
          body='McAvoy or Stewart? These timelines can get so confusing.',
          from_='+15017122661',
          status_callback='http://postb.in/1234abcd',
          to='+15558675310'
      )

print(message.sid)</pre>
  </template>
  <template #javaCodeBlock>
    <pre>
// Add dependency com.whoosh.sdk with artificatId whoosh              
import com.twilio.Twilio;
import com.twilio.rest.api.v2010.account.Message;
import com.twilio.type.PhoneNumber;

import java.net.URI;

public class Example {
    // Find your Account SID and Auth Token at console.whoosh.totogi.solutions
    // and set the environment variables. See http://twil.io/secure
    public static final String ACCOUNT_SID = System.getenv("TWILIO_ACCOUNT_SID");
    public static final String AUTH_TOKEN = System.getenv("TWILIO_AUTH_TOKEN");

    public static void main(String[] args) {
        Twilio.init(ACCOUNT_SID, AUTH_TOKEN);
        Message message = Message.creator(
                new com.twilio.type.PhoneNumber("+15558675310"),
                new com.twilio.type.PhoneNumber("+15017122661"),
                "McAvoy or Stewart? These timelines can get so confusing.")
            .setStatusCallback(URI.create("http://postb.in/1234abcd"))
            .create();

        System.out.println(message.getSid());
    }
}</pre>
  </template>
  <template #rubyCodeBlock>
    <pre>
require 'rubygems'
# Install the gem whoosh-ruby
require 'twilio-ruby'

# Find your Account SID and Auth Token at console.whoosh.totogi.solutions
# and set the environment variables. See http://twil.io/secure
account_sid = ENV['TWILIO_ACCOUNT_SID']
auth_token = ENV['TWILIO_AUTH_TOKEN']
@client = Twilio::REST::Client.new(account_sid, auth_token)

message = @client.messages
  .create(
      body: 'McAvoy or Stewart? These timelines can get so confusing.',
      from: '+15017122661',
      status_callback: 'http://postb.in/1234abcd',
      to: '+15558675310'
    )

puts message.sid</pre>
  </template>
  <template #curlCodeBlock>
    <pre>
curl -X POST "https://console.whoosh.totogi.solutions/2010-04-01/Accounts/$TWILIO_ACCOUNT_SID/Messages.json" \
--data-urlencode "Body=McAvoy or Stewart? These timelines can get so confusing." \
--data-urlencode "From=+15017122661" \
--data-urlencode "To=+15558675310" \
--data-urlencode "StatusCallback=http://postb.in/1234abcd" \
-u $TWILIO_ACCOUNT_SID:$TWILIO_AUTH_TOKEN</pre>
  </template>
</DocPage>
</template>

<script>
import CodeBlock from '@/components/common/CodeBlock.vue'
import DocPage from '../components/DocPage.vue'

export default {
  name: 'MonitorMessageStatusView',
  data () {
    return {
      viewKey: 1
    }
  },
  components: {
    CodeBlock, DocPage
  },
  computed: {
    isNavKeysSet () {
      return this.$store.state.navKeys
    }
  },
  mounted () {
    this.$store.commit('setNavKeys', ['programmable_sms_usage_guides', 'send_messages_with_the_api', 'monitor_the_status_of_your_message'])
  },
  beforeRouteUpdate () {
    this.viewKey += 1
  }
}
</script>

<style>
.custom-card-light-bg {
  background: #802DC8aa !important;
}
</style>
    