<template>
<div>
  <CNavbar
    expand="lg"
    color-scheme="dark"
    class="main-navbar-background py-0"
    placement="fixed-top"
    style="position: relative;"
  >
    <CContainer fluid>
      <CNavbarBrand href="#" class="py-2">
        <img :src="navbarLogo" height="30" class="d-inline-block align-top" />
        &nbsp;&nbsp;
        Whoosh Docs
      </CNavbarBrand>
      <CNavbarNav class="h-100" style="position: relative;">
        <CNavItem :class="activeLinkClasses" style="box-sizing: border-box">
          <CNavLink
            href="https://console.whoosh.totogi.solutions"
            target="_blank"
          >
            Console
          </CNavLink>
        </CNavItem>
      </CNavbarNav>
    </CContainer>
  </CNavbar>
</div>
</template>
    
<script>
import totogiLogo from '../assets/totogi-logo-white-pink-rgb.webp'

export default {
  name: 'MainNav',
  data () {
    return {
      navItemClasses: 'py-1 px-3 border-bottom-4',
      navbarLogo: totogiLogo
    }
  },
  computed: {
    activeLinkClasses () {
      return `${this.navItemClasses}`
    },
    inactiveLinkClasses () {
      return `${this.navItemClasses} inactive-link-border`
    }
  },
}
</script>

<style>
.main-navbar-background {
  background-color: #001D3D;
}

.active-link-border {
  border-bottom: solid;
  border-color: #FF4F58;
}

.inactive-link-border {
  border-bottom: solid;
  border-color: rgba(0, 0, 0, 0);
}
</style>
