<template>
<DemoVideo
  title="Java SDK Demo"
  :demoVideo="videoFile"
  :navKeys="['demos', 'java_demo']"
  :key="viewKey"
/>
</template>

<script>
import whoosh_java from '../assets/whoosh_java_demo_local_jar.mp4'

import DemoVideo from '@/components/DemoVideo.vue'

export default {
  data () {
    return {
      videoFile: whoosh_java,
      viewKey: 1
    }
  },
  components: { DemoVideo },
  beforeRouteUpdate () {
    this.viewKey += 1
  }
}
</script>