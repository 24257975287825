<template>
<pre class="line-numbers custom-prismjs-block">
<code data-prismjs-copy="Copy" :class="'language-' + language"><slot name="codeBlock"></slot></code>
</pre>
</template>

<script>
import Prism from 'prismjs'
import 'prismjs/plugins/autoloader/prism-autoloader'
import 'prismjs/plugins/line-numbers/prism-line-numbers'
import 'prismjs/plugins/toolbar/prism-toolbar'
import 'prismjs/plugins/copy-to-clipboard/prism-copy-to-clipboard'
import 'prism-themes/themes/prism-xonokai.css'
import 'prismjs/plugins/line-numbers/prism-line-numbers.css'
import 'prismjs/plugins/toolbar/prism-toolbar.css'
import 'prismjs/components/prism-javascript'
import 'prismjs/components/prism-python'
import 'prismjs/components/prism-java'
import 'prismjs/components/prism-ruby'
import 'prismjs/components/prism-bash'

export default {
  props: {
    language: {
      type: String,
      required: true
    }
  },
  mounted () {
    window.Prism = window.Prism || {}
    window.Prism.manual = true
    Prism.highlightAll()
  }
}
</script>

<style>
.custom-prismjs-block {
  border: none !important;
  background: #222222 !important;
}
</style>