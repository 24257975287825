export const navStructure = [
  {
    title: 'Get Started',
    collapsedIcon: 'fa-solid fa-folder',
    expandedIcon: 'fa-solid fa-folder-open',
    expanded: true,
    navKey: 'get_started',
    children: [
      {
        title: 'How to Work with your Free Whoosh Trial Account',
        collapsedIcon: 'fa-solid fa-file-lines'
      },
      {
        title: 'Choose the Right Whoosh Phone Number Type',
        collapsedIcon: 'fa-solid fa-file-lines'
      },
      {
        title: 'No-code Quickstart',
        collapsedIcon: 'fa-solid fa-file-lines'
      },
      {
        title: 'PHP Quickstart',
        collapsedIcon: 'fa-solid fa-file-lines'
      },
      {
        title: '.NET Framework Quickstart',
        collapsedIcon: 'fa-solid fa-file-lines'
      },
      {
        title: '.NET Core Quickstart',
        collapsedIcon: 'fa-solid fa-file-lines'
      },
      {
        title: 'Java Quickstart',
        collapsedIcon: 'fa-solid fa-file-lines',
        navKey: 'java_quickstart',
        routerKey: 'JavaQuickstart'
      },
      {
        title: 'Node.js Quickstart',
        collapsedIcon: 'fa-solid fa-file-lines',
        navKey: 'nodejs_quickstart',
        routerKey: 'NodeQuickstart'
      },
      {
        title: 'Ruby Quickstart',
        collapsedIcon: 'fa-solid fa-file-lines',
        navKey: 'ruby_quickstart',
        routerKey: 'RubyQuickstart'
      },
      {
        title: 'Python Quickstart',
        collapsedIcon: 'fa-solid fa-file-lines',
        navKey: 'python_quickstart',
        routerKey: 'PythonQuickstart'
      },
      {
        title: 'Go Quickstart',
        collapsedIcon: 'fa-solid fa-file-lines'
      }
    ]
  },
  {
    title: 'API Reference',
    collapsedIcon: 'fa-solid fa-folder',
    expandedIcon: 'fa-solid fa-folder-open',
    expanded: true,
    children: [
      {
        title: 'API Overview',
        collapsedIcon: 'fa-solid fa-file-lines'
      },
      {
        title: 'Message Resource',
        collapsedIcon: 'fa-solid fa-file-lines'
      },
      {
        title: 'MessageFeedback Resource',
        collapsedIcon: 'fa-solid fa-file-lines'
      },
      {
        title: 'SMS Media Resource',
        collapsedIcon: 'fa-solid fa-file-lines'
      },
      {
        title: 'Service Resource',
        collapsedIcon: 'fa-solid fa-file-lines'
      },
      {
        title: 'Pricing Resource',
        collapsedIcon: 'fa-solid fa-file-lines'
      }
    ]
  },
  {
    title: 'TwiML',
    collapsedIcon: 'fa-solid fa-folder',
    expandedIcon: 'fa-solid fa-folder-open',
    expanded: true,
    children: [
      {
        title: 'Overview',
        collapsedIcon: 'fa-solid fa-file-lines'
      },
      {
        title: '<Message>',
        collapsedIcon: 'fa-solid fa-file-lines'
      },
      {
        title: '<Redirect>',
        collapsedIcon: 'fa-solid fa-file-lines'
      }
    ]
  },
  {
    title: 'Tutorials',
    collapsedIcon: 'fa-solid fa-folder',
    expandedIcon: 'fa-solid fa-folder-open',
    expanded: true,
    children: [
      {
        title: 'How to receive and reply to SMS messages',
        collapsedIcon: 'fa-solid fa-file-lines'
      },
      {
        title: 'Build Server Notifications with SMS',
        collapsedIcon: 'fa-solid fa-file-lines'
      },
      {
        title: 'How to send SMS and MMS messages',
        collapsedIcon: 'fa-solid fa-file-lines'
      },
      {
        title: 'How to track delivery status of messages',
        collapsedIcon: 'fa-solid fa-file-lines'
      },
      {
        title: 'Appointment Reminders',
        collapsedIcon: 'fa-solid fa-file-lines'
      },
      {
        title: 'How to retrieve and modify message history',
        collapsedIcon: 'fa-solid fa-file-lines'
      },
      {
        title: 'Browse all SMS tutorials',
        collapsedIcon: 'fa-solid'
      }
    ]
  },
  {
    title: 'Programmable SMS Usage Guides',
    collapsedIcon: 'fa-solid fa-folder',
    expandedIcon: 'fa-solid fa-folder-open',
    expanded: true,
    navKey: 'programmable_sms_usage_guides',
    children: [
      {
        title: 'Send messages with the API',
        collapsedIcon: 'fa-solid fa-file-lines',
        expanded: false,
        navKey: 'send_messages_with_the_api',
        children: [
          {
            title: 'Send an SMS with Whoosh\'s API',
            navKey: 'send_an_sms_with_whoosh_api',
            routerKey: 'SendMessage'
          },
          {
            title: 'Monitor the status of your message',
            navKey: 'monitor_the_status_of_your_message',
            routerKey: 'MonitorMessageStatus'
          }
        ]
      },
      {
        title: 'Send message feedback to Twilio',
        collapsedIcon: 'fa-solid fa-file-lines'
      },
      {
        title: 'App verification with Twilio SMS',
        collapsedIcon: 'fa-solid fa-file-lines'
      },
      {
        title: 'What to Know Before Sending International SMS Messages',
        collapsedIcon: 'fa-solid fa-file-lines'
      },
      {
        title: 'Migrate to Twilio',
        collapsedIcon: 'fa-solid fa-file-lines'
      }
    ]
  },
  {
    title: 'A2P 10DLC',
    collapsedIcon: 'fa-solid fa-folder',
    expandedIcon: 'fa-solid fa-folder-open',
    expanded: true,
    children: [
      {
        title: 'US A2P 10DLC Overview',
        collapsedIcon: 'fa-solid fa-file-lines'
      },
      {
        title: 'Register Your Business for A2P 10DLC',
        collapsedIcon: 'fa-solid fa-folder',
        expandedIcon: 'fa-solid fa-folder-open',
        expanded: false,
        children: [
          {
            title: 'Direct Brand US A2P 10DLC Overview',
            collapsedIcon: 'fa-solid fa-file-lines'
          },
          {
            title: 'Direct Standard and Low Volume Standard registration overview',
            collapsedIcon: 'fa-solid fa-file-lines'
          },
          {
            title: 'US A2P 10DLC Direct Brand Registration for Government and Non-Profit Agencies',
            collapsedIcon: 'fa-solid fa-file-lines'
          },
          {
            title: 'Direct Sole Proprietor Registration Overview',
            collapsedIcon: 'fa-solid fa-file-lines'
          }
        ]
      },
      {
        title: 'Register Your Customers for A2P 10DLC',
        collapsedIcon: 'fa-solid fa-folder',
        expandedIcon: 'fa-solid fa-folder-open',
        expanded: false,
        children: [
          {
            title: 'ISV Onboarding Guide',
            collapsedIcon: 'fa-solid fa-file-lines'
          },
          {
            title: 'ISV Standard Registration Overview',
            collapsedIcon: 'fa-solid fa-file-lines'
          },
          {
            title: 'ISV Standard API Registration Walthrough',
            collapsedIcon: 'fa-solid fa-file-lines'
          },
          {
            title: 'New ISV Sole Proprietor API Registration Walkthrough',
            collapsedIcon: 'fa-solid fa-file-lines'
          },
          {
            title: 'ISV Sole Proprietor API Registration Walkthrough for Existing Starter Registrations',
            collapsedIcon: 'fa-solid fa-file-lines'
          },
          {
            title: 'Troubleshooting and Resubmitting Standard Brands',
            collapsedIcon: 'fa-solid fa-file-lines'
          },
          {
            title: 'Upgrading Starter Brands to Standard Brands',
            collapsedIcon: 'fa-solid fa-file-lines'
          }
        ]
      },
      {
        title: 'Externally Registered Campaigns API',
        collapsedIcon: 'fa-solid fa-file-lines'
      },
      {
        title: 'Mock Brand and Campaign API',
        collapsedIcon: 'fa-solid fa-file-lines'
      }
    ]
  },
  {
    title: 'Troubleshooting',
    collapsedIcon: 'fa-solid fa-folder',
    expandedIcon: 'fa-solid fa-folder-open',
    expanded: true,
    children: [
      {
        title: 'Whoosh\'s Debugging Tools',
        collapsedIcon: 'fa-solid fa-file-lines'
      },
      {
        title: 'Debugging Common SMS Issues',
        collapsedIcon: 'fa-solid fa-file-lines'
      },
      {
        title: 'Accepted Content Types for Media',
        collapsedIcon: 'fa-solid fa-file-lines'
      },
      {
        title: 'Messaging Segment Calculator'
      }
    ]
  },
  {
    title: 'Programmable Messaging',
    collapsedIcon: 'fa-solid fa-folder',
    expandedIcon: 'fa-solid fa-folder-open',
    expanded: true,
    children: [
      {
        title: 'All Programmable Messaging Docs'
      },
      {
        title: 'Messaging Service Features'
      },
      {
        title: 'API Reference: Messaging Services'
      },
      {
        title: 'Whoosh Messaging Channels'
      },
      {
        title: 'Our Request to your Webhook URL',
        collapsedIcon: 'fa-solid fa-file-lines'
      },
      {
        title: 'Looking for the WhatsApp Business API with Twilio?'
      },
      {
        title: 'Twilio Conversations for two-way messaging'
      }
    ]
  },
  {
    title: 'Demos',
    collapsedIcon: 'fa-solid fa-folder',
    expandedIcon: 'fa-solid fa-folder-open',
    expanded: true,
    navKey: 'demos',
    children: [
      {
        title: 'Java SDK Demo',
        collapsedIcon: 'fa-solid fa-video',
        navKey: 'java_demo',
        routerKey: 'JavaDemo'
      },
      {
        title: 'Node.js SDK Demo',
        collapsedIcon: 'fa-solid fa-video',
        navKey: 'node_demo',
        routerKey: 'NodeDemo'
      },
      {
        title: 'Python SDK Demo',
        collapsedIcon: 'fa-solid fa-video',
        navKey: 'python_demo',
        routerKey: 'PythonDemo'
      },
      {
        title: 'Ruby SDK Demo',
        collapsedIcon: 'fa-solid fa-video',
        navKey: 'ruby_demo',
        routerKey: 'RubyDemo'
      },
      {
        title: 'Curl Demo',
        collapsedIcon: 'fa-solid fa-video',
        navKey: 'curl_demo',
        routerKey: 'CurlDemo'
      }
    ]
  }
]