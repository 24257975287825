<template>
<div>
  <div class="py-2"></div>
  <div class="d-flex flex-row-reverse">
    <CCard style="max-width: 200px;">
      <CCardBody class="p-2">
        <div class="pb-2">Rate this page:</div>
        <div style="font-size: 120%">
          <span class="d-inline px-1" @mouseover="hoverEnter(1)" @mouseleave="hoverLeave" @click="updateRating(1)">
            <font-awesome-icon :icon="ratingValue1Icon"></font-awesome-icon>
          </span>
          <span class="d-inline px-1" @mouseover="hoverEnter(2)" @mouseleave="hoverLeave" @click="updateRating(2)">
            <font-awesome-icon :icon="ratingValue2Icon"></font-awesome-icon>
          </span>
          <span class="d-inline px-1" @mouseover="hoverEnter(3)" @mouseleave="hoverLeave" @click="updateRating(3)">
            <font-awesome-icon :icon="ratingValue3Icon"></font-awesome-icon>
          </span>
          <span class="d-inline px-1" @mouseover="hoverEnter(4)" @mouseleave="hoverLeave" @click="updateRating(4)">
            <font-awesome-icon :icon="ratingValue4Icon"></font-awesome-icon>
          </span>
          <span class="d-inline px-1" @mouseover="hoverEnter(5)" @mouseleave="hoverLeave" @click="updateRating(5)">
            <font-awesome-icon :icon="ratingValue5Icon"></font-awesome-icon>
          </span>
        </div>
      </CCardBody>
    </CCard>
  </div>
  <div class="py-3"></div>
</div>
</template>

<script>
export default {
  data () {
    return {
      ratingValue: 0
    }
  },
  computed: {
    ratingKey () {
      return `page_rating_${this.$route.name}`
    },
    ratingValue1Icon () {
      return (this.ratingValue > 0 ? 'fa-solid fa-star' : 'fa-regular fa-star' )
    },
    ratingValue2Icon () {
      return (this.ratingValue > 1 ? 'fa-solid fa-star' : 'fa-regular fa-star' )
    },
    ratingValue3Icon () {
      return (this.ratingValue > 2 ? 'fa-solid fa-star' : 'fa-regular fa-star' )
    },
    ratingValue4Icon () {
      return (this.ratingValue > 3 ? 'fa-solid fa-star' : 'fa-regular fa-star' )
    },
    ratingValue5Icon () {
      return (this.ratingValue > 4 ? 'fa-solid fa-star' : 'fa-regular fa-star' )
    }
  },
  mounted () {
    this.loadRating()
  },
  methods: {
    loadRating () {
      const existingRating = window.localStorage.getItem(this.ratingKey)
      if (existingRating !== null) {
        this.ratingValue = Number(existingRating)
      } else {
        this.ratingValue = 0
      }
    },
    updateRating (newRatingValue) {
      this.ratingValue = Number(newRatingValue)
      window.localStorage.setItem(this.ratingKey, newRatingValue)
    },
    hoverEnter (ratingValue) {
      this.ratingValue = Number(ratingValue)
    },
    hoverLeave () {
      this.loadRating()
    }
  }
}
</script>