<template>
<DocPage v-if="isNavKeysSet" :codeBlocksUsed="['python']" :key="viewKey">
  <template #mainContent>
    <h1>Programmable SMS Python Quickstart</h1>
    <hr />
    <p>
      This Programmable Messaging Quickstart will walk you through the entire process step-by-step, starting with setting up your Whoosh account all the way through sending an SMS using a Messaging Service.
    </p>
    <QuickstartSection />
    <hr />
    <SignUpSection />
    <hr />
    <h1>Install Python and the Whoosh Helper Library</h1>
    <p>To send your first SMS, you’ll need to have Python and the Whoosh Python helper library installed.</p>
    <p>If you’re using a Mac or Linux machine, you probably already have Python installed. You can check this by opening up a terminal and running the following command:</p>
    <CodeBlock language="bash">
      <template #codeBlock>
        <pre>python3 --version</pre>
      </template>
    </CodeBlock>
    <h2 class="pt-3 pb-2">Install Python</h2>
    <p>You should see something like:</p>
    <CodeBlock language="bash">
      <template #codeBlock>
        <pre>$ python3 --version
Python 3.7</pre>
      </template>
    </CodeBlock>
    <p>
      Windows users can follow this <a target="_blank" href="https://www.digitalocean.com/community/tutorials/how-to-install-python-3-and-set-up-a-local-programming-environment-on-windows-10">excellent tutorial for installing Python on Windows</a>, or follow the <a target="_blank" href="https://docs.python.org/3/using/windows.html">instructions from Python's documentation</a>.
    </p>
    <hr />
    <h1>Send an outbound SMS message with Python</h1>
    <p>Now that we have Python and <mark>whoosh-python</mark> installed, we can send an outbound text message with a single API request. Create and open a new file called <mark>send_sms.py</mark> and type or paste in this code sample.</p>
    <p>
      You'll need to edit this file a little more before your message will send.
    </p>
    <h2  class="pt-3 pb-2">Replace the placeholder credential values</h2>
    <p>
      Swap the placeholder values for <mark>accountSid</mark> and <mark>authToken</mark> with your personal Whoosh credentials. Go to https://console.whoosh.totogi.solutions and log in. On this page, you'll find your unique Account SID and Auth Token, which you'll need any time you send messages through the Whoosh client
    </p>
    <p>
      Open <mark>send_sms.py</mark> and replace the values for <mark>accountSid</mark> and <mark>authToken</mark> with your unique values.
    </p>
    <CCard>
      <CCardBody>
        Please note: it's okay to hardcode your credentials when getting started, but you should use environment variables to keep them secret before deploying to production. 
      </CCardBody>
    </CCard>
    <h2  class="pt-3 pb-2">Replace the "to" phone number</h2>
    <p>
      Replace the <mark>to</mark> phone number with your mobile phone number. This can be any phone number that can receive text messages, but it's a good idea to test with your own phone so you can see the magic happen! As above, you should use E.164 formatting for this value.
    </p>
    <p>
      <mark>[+][country code][phone number including area code]</mark>
    </p>
    <p>
      Save your changes and run this script from your terminal:
    </p>
    <CodeBlock language="bash">
      <template #codeBlock>
        <pre>python send_sms.py</pre>
      </template>
    </CodeBlock>
    <p>That's it! In a few moments, you should receive an SMS from your Whoosh number on your phone.</p>
  </template>
  <template #pythonCodeBlock>
    <pre>
# Download the helper library from https://pypi.org/project/WhooshSms/
import os
from twilio.rest import Client


# Find your Account SID and Auth Token at console.whoosh.totogi.solutions
# and set the environment variables. See http://twil.io/secure
account_sid = os.environ['TWILIO_ACCOUNT_SID']
auth_token = os.environ['TWILIO_AUTH_TOKEN']
client = Client(account_sid, auth_token)

message = client.messages \
                .create(
                     body="Join Earth's mightiest heroes. Like Kevin Bacon.",
                     from_='+15017122661',
                     to='+15558675310'
                 )

print(message.sid)</pre>
  </template>
</DocPage>
</template>

<script>
import CodeBlock from '@/components/common/CodeBlock.vue'
import DocPage from '../components/DocPage.vue'
import SignUpSection from '@/components/SignUpSection.vue'
import QuickstartSection from '@/components/QuickstartSection.vue'

export default {
  name: 'SendMessageView',
  data () {
    return {
      viewKey: 1
    }
  },
  components: {
    CodeBlock, DocPage, SignUpSection, QuickstartSection
  },
  computed: {
    isNavKeysSet () {
      return this.$store.state.navKeys
    }
  },
  mounted () {
    this.$store.commit('setNavKeys', ['get_started', 'python_quickstart'])
  },
  beforeRouteUpdate () {
    this.viewKey += 1
  }
}
</script>
